<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
} from "@shopware-pwa/helpers-next";
import type { Schemas } from "~/api-types/storeApiTypes";
import { mdiChevronDown } from "@mdi/js";
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
};

const { navigationElements } = useNavigation();
const currentMenuPosition = ref<string | undefined>(undefined);
const resetActiveClass = ref<boolean>(true);

const route = useRoute();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

onMounted(() => {
  let currentNaviagtionElement: NavigationElement | undefined;
  if (navigationElements.value) {
    currentNaviagtionElement = findNavigationElement(
      route.path.slice(1),
      navigationElements.value,
    );
  }
  if (currentNaviagtionElement) {
    updateActiveClass(
      currentNaviagtionElement.id,
      currentNaviagtionElement.parentId,
    );
  }
});

const findNavigationElement = (
  routePath: string,
  navigation: NavigationElement[],
): NavigationElement | undefined => {
  for (let i = 0; i < navigation.length; i++) {
    const navigationElement = navigation[i];
    const seoUrls = navigationElement.seoUrls as
      | Schemas["SeoUrl"][]
      | undefined;
    if (seoUrls) {
      for (let j = 0; j < seoUrls.length; j++) {
        const currentSeoUrl = seoUrls[j];
        if (currentSeoUrl && currentSeoUrl.seoPathInfo === routePath) {
          return navigationElement;
        }
      }
    }
    const children = navigationElement.children;
    if (children) {
      const foundElement = findNavigationElement(routePath, children);
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return undefined;
};

const onUpdateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  updateActiveClass(navigationId, parentId);
};

const resetNavigationActiveClass = (navigation: NavigationElement[]) => {
  for (let ni = 0; ni < navigation.length; ++ni) {
    navigation[ni].activeClass = false;
    const children = navigation[ni].children;
    if (children) {
      resetNavigationActiveClass(children);
    }
  }
};

const updateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  const setNavigationActiveClass = (
    navigation: NavigationElement[],
    navigationId: string,
    parentId: string | undefined,
  ) => {
    for (let ni = 0; ni < navigation.length; ++ni) {
      if (navigation[ni].id === navigationId) {
        navigation[ni].activeClass = true;
      }
      if (navigation[ni].id == parentId) {
        navigation[ni].activeClass = true;
        if (navigationElements.value) {
          setNavigationActiveClass(
            navigationElements.value,
            navigationId,
            navigation[ni].parentId,
          );
        }
      }
      const children = navigation[ni].children;
      if (children) {
        setNavigationActiveClass(children, navigationId, parentId);
      }
    }
  };

  if (navigationElements.value) {
    resetNavigationActiveClass(navigationElements.value);
    setNavigationActiveClass(navigationElements.value, navigationId, parentId);
    resetActiveClass.value = false;
  }
};
// reset when route.path changes
watch(
  () => route.path,
  () => {
    if (resetActiveClass.value == true && navigationElements.value) {
      resetNavigationActiveClass(navigationElements.value);
    }
    resetActiveClass.value = true;
  },
);
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <nav aria-label="Top navigation" role="menu" class="d-flex">
    <template
      v-for="navigationElement in navigationElements"
      :key="navigationElement.id"
    >
      <v-menu
        v-if="navigationElement.children.length > 0"
        :open-on-hover="true"
        open-delay="60"
        offset="16"
      >
        <template #activator="{ props }">
          <!-- Category with sub items -->
          <v-btn
            variant="plain"
            role="menuitem"
            class="text-body-1"
            v-bind="props"
            :append-icon="mdiChevronDown"
            :to="formatLink(getCategoryRoute(navigationElement))"
            :target="
              navigationElement.externalLink || navigationElement.linkNewTab
                ? '_blank'
                : ''
            "
          >
            {{ getTranslatedProperty(navigationElement, "name") }}
          </v-btn>
        </template>

        <v-list bg-color="gray800" class="rounded-t-0 rounded-b-lg">
          <v-list-item
            v-for="subNavigationElement in navigationElement.children"
            :key="subNavigationElement.id"
            :value="subNavigationElement.id"
            :to="formatLink(getCategoryRoute(subNavigationElement))"
            :title="getTranslatedProperty(subNavigationElement, 'name')"
          />
        </v-list>
      </v-menu>

      <!-- Category with no sub items -->
      <v-btn
        v-else
        role="menuitem"
        class="text-body-1"
        variant="plain"
        :to="formatLink(getCategoryRoute(navigationElement))"
        :target="
          navigationElement.externalLink || navigationElement.linkNewTab
            ? '_blank'
            : ''
        "
      >
        {{ getTranslatedProperty(navigationElement, "name") }}
      </v-btn>
    </template>

    <div
      v-for="navigationElement in navigationElements"
      :key="navigationElement.id"
      class="relative pl-4 flex-nowrap d-none"
      @mouseover="currentMenuPosition = navigationElement.id"
    >
      <NuxtLink
        role="menuitem"
        class="text-body-1"
        :target="
          navigationElement.externalLink || navigationElement.linkNewTab
            ? '_blank'
            : ''
        "
        :to="formatLink(getCategoryRoute(navigationElement))"
        :class="{
          'link-active': (navigationElement as NavigationElement).activeClass,
        }"
        @click="
          updateActiveClass(navigationElement.id, navigationElement.parentId)
        "
      >
        {{ getTranslatedProperty(navigationElement, "name") }}
      </NuxtLink>

      <!--
            Flyout menu, show/hide based on flyout menu state.

            Entering: "transition ease-out duration-200"
              From: "opacity-0 translate-y-1"
              To: "opacity-100 translate-y-0"
            Leaving: "transition ease-in duration-150"
              From: "opacity-100 translate-y-0"
              To: "opacity-0 translate-y-1"
          -->
      <client-only>
        <div
          v-if="
            currentMenuPosition === navigationElement.id &&
            navigationElement?.children.length
          "
          class="absolute"
          @mouseleave="currentMenuPosition = undefined"
        >
          <div class="">
            <template v-if="navigationElement.children.length > 0">
              <LayoutTopNavigationRecursive
                :navigation-element-children="navigationElement.children"
                @update-active-class="onUpdateActiveClass"
              />
            </template>
            <div class="">
              <div
                class="flow-root"
                v-html="getTranslatedProperty(navigationElement, 'description')"
              />
            </div>
          </div>
        </div>
      </client-only>
    </div>
  </nav>
</template>

<style scoped>
/*nav .link-active {
  @apply text-secondary-900 bg-primary-500 bg-opacity-10 rounded-lg;
}*/
</style>
